html.menu-open, body.menu-open {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }

img {
  max-width: 100%;
  height: auto; }

.alignright {
  margin: 0 0 1em 1em;
  float: right; }

.alignleft {
  margin: 0 1em 1em 0;
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 47.9375em) {
  .aligncenter,
  .alignright,
  .alignleft {
    display: block;
    margin-left: 0;
    margin-right: 0;
    float: none; } }

#MyWishListItems .MyWishListItem .ProductInfo .ProductNameWeb {
  height: auto;
  margin-bottom: 0.75em; }

#site #content .LeftInnerContent .StandardNav #LeftNavProductCatalog {
  margin: 1em auto 4em; }

#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo,
#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo .SubCategoryInfoBGWrapper {
  background: none !important; }

#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryImage,
#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo,
#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem {
  height: auto;
  min-height: auto;
  line-height: inherit; }

#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo {
  padding-bottom: 20px; }

#site #content #HomeContainer #RotatingBanner li span {
  display: none !important; }

.vDialog .vDialogClose {
  z-index: 1; }

.vDialog .vDialogContent {
  z-index: 0; }

.sidebarList {
  padding: 0;
  list-style: none; }
  .sidebarList li:not(:last-child):after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    margin: 10px 0;
    background: transparent url("/images/leftContent-Divider.png") no-repeat center; }

.backToTop a {
  color: #c00f33; }
  .backToTop a:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #c00f33; }

.mobileTabs {
  margin: 0 -20px; }
  .mobileTabs .tabSectionTitle {
    padding: 0.875em 1.429em;
    background: #ffd87c;
    font-size: 1.167em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: inset 0.5em -0.2em 1.7em rgba(0, 0, 0, 0.25);
    cursor: pointer; }
  .mobileTabs .tabSectionContent {
    display: none;
    padding: 1em 1.6em; }
  .mobileTabs .tabSection.is-active .tabSectionTitle {
    background: #003768;
    color: #fff; }
  .mobileTabs .tabSection.is-active .tabSectionContent {
    display: block; }
  .mobileTabs #ProductSpecification {
    padding: 0;
    margin: 0 -1.6em;
    list-style: none; }
    .mobileTabs #ProductSpecification li {
      display: flex;
      align-items: center;
      padding: 0.5em 1.6em;
      border-bottom: 1px solid #424242; }
    .mobileTabs #ProductSpecification .AttributeTypeName {
      flex: 0 0 12em;
      font-weight: bold; }
  .mobileTabs .Header {
    display: block;
    border-bottom: 1px solid #000;
    padding: 0.3em 1.6em;
    margin: 0 -1.6em 0.75em; }
  .mobileTabs .Color {
    display: block;
    width: 5.333em;
    height: 5.333em;
    margin: 0 auto 0.5em;
    border-radius: 50%; }
    .mobileTabs .ColorsList {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none; }
    .mobileTabs .ColorItem {
      flex: 0 0 9.167em;
      padding: 0 0.5em;
      margin-bottom: 1em;
      text-align: center; }

.navigationButton {
  display: block;
  width: 2em;
  height: 2em;
  padding: 0;
  border: none;
  position: relative;
  background: none;
  font-size: 1em;
  appearance: none;
  cursor: pointer; }
  .navigationButton:before {
    content: '';
    width: 100%;
    height: 0.5em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #1b3a67;
    box-shadow: 0 -0.75em 0 #1b3a67, 0 0.75em 0 #1b3a67; }

.navigation--mobile {
  width: 90%;
  max-width: 40em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translateX(100%);
  overflow: auto;
  background: #61a6d1;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
  transition: transform 0.3s; }
  .touch .navigation--mobile {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .navigation--mobile.is-open {
    transform: translateX(0); }
  .navigation--mobile .search {
    display: none;
    align-items: center;
    padding: 1em;
    list-style: none; }
    .navigation--mobile .search a {
      display: block;
      order: 2;
      width: 3.167em;
      height: 3.167em;
      margin-left: 1em;
      background: url(../images/search-icon.svg) no-repeat;
      border: 1px solid #fff;
      border-radius: 50%; }
    .navigation--mobile .search .input {
      flex: 1;
      order: 1; }
      .navigation--mobile .search .input input {
        width: 100%;
        height: 3.167em;
        padding: 0.5em 0.75em;
        border: 1px solid #c5c2c1;
        font-size: 1em;
        color: #6f6764; }
    @media (max-width: 33.75em) {
      .navigation--mobile .search {
        display: flex; } }

.navigationMenu {
  flex-basis: 100%;
  min-height: calc(100% - 2em);
  padding: 0;
  border: 1px solid #fff;
  margin: 1em;
  list-style: none; }

.navigationSubMenu {
  display: none;
  margin: 0;
  padding: 0 0 0 2em;
  border: none;
  border-top: 1px solid #fff; }
  .navigationSubMenuButton {
    flex: 0 0 4em;
    padding: 0;
    border: none;
    border-left: 1px solid #fff;
    position: relative;
    background: none;
    font-size: 1em;
    appearance: none;
    cursor: pointer; }
    .navigationSubMenuButton:before {
      content: '';
      width: 1em;
      height: 1em;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: transform 0.2s; }
    .navigationSubMenuButton:hover {
      background-color: #327cad;
      box-shadow: inset 0.4em 0.4em 0.7em #274962; }
    .navigationSubMenuButton.is-open:before {
      transform: translate(-50%, -50%) rotate(45deg); }

.navigationItem {
  display: flex;
  flex-wrap: wrap; }
  .navigationItem a {
    display: block;
    flex: 1;
    margin: 0;
    padding: 1em;
    color: #fff;
    font-size: 1.167em;
    font-weight: bold;
    text-transform: uppercase; }
    .navigationItem a:hover {
      background-color: #327cad;
      box-shadow: inset 0.4em 0.4em 0.7em #274962; }
  .navigationItem:not(:last-child) {
    border-bottom: 1px solid #fff; }
  .navigationItem:not(.navigationSubItem):last-child {
    border-bottom: 1px solid #fff; }

.navigationSubItem {
  border-left: 1px solid #fff; }

.mobileSlider {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none; }
  .mobileSliderContainer {
    max-width: 45em;
    margin: 2em auto; }
  .mobileSlider .slick-track {
    width: 100%; }
  .mobileSlider .slick-list {
    flex: 1; }
  .mobileSlider .slick-arrow {
    flex: 0 0 2.333em;
    background: transparent center no-repeat;
    border: none;
    padding: 0;
    overflow: hidden;
    font-size: 1em;
    text-indent: 100%;
    white-space: nowrap;
    appearance: none;
    cursor: pointer; }
  .mobileSlider .slick-prev {
    margin-right: 0.5em;
    background-image: url("/images/Product_Thumb_LeftArrow.png"); }
  .mobileSlider .slick-next {
    margin-left: 0.5em;
    background-image: url("/images/Product_Thumb_RightArrow.png"); }
  .mobileSlider .slick-slide {
    border: 5px solid transparent;
    margin: 0 0.5em; }
    .mobileSlider .slick-slide.is-selected, .mobileSlider .slick-slide:hover {
      border-color: #ffd87c; }
    .mobileSlider .slick-slide img {
      width: 100%;
      height: auto;
      border: 1px solid #000; }
    @media (max-width: 26.25em) {
      .mobileSlider .slick-slide {
        margin: 0 5em; } }

.available-literature {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 28em;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  font-size: 1.167em; }
  .available-literature li {
    flex: 1 1 calc(50% - 2em);
    margin: 1em;
    text-align: center; }
  .available-literature img {
    display: block;
    margin: 0 auto 1em; }
  .no-flexbox .available-literature:after {
    content: '';
    display: table;
    clear: both; }
  .no-flexbox .available-literature li {
    width: calc(50% - 2em);
    float: left; }
    .no-flexbox .available-literature li:nth-child(2n+1) {
      clear: left; }

.application-shots {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  font-size: 1.167em; }
  .application-shots li {
    flex-basis: 100%;
    margin: 1em;
    text-align: center; }
    @media (min-width: 30em) {
      .application-shots li {
        flex: 0 1 calc(50% - 2em); } }
  .application-shots img {
    display: block;
    margin: 0 auto 1em; }
  .no-flexbox .application-shots:after {
    content: '';
    display: table;
    clear: both; }
  @media (min-width: 30em) {
    .no-flexbox .application-shots li {
      width: calc(50% - 2em);
      float: left; }
      .no-flexbox .application-shots li:nth-child(2n+1) {
        clear: left; } }

.state table {
  width: 100%;
  margin: 2em 0;
  border-collapse: collapse; }

.state thead th {
  position: relative;
  background-color: #ffebbd;
  text-align: left; }
  .state thead th:not(:last-child):after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff; }

.state tbody td {
  background-color: #eff6fa; }

.state th, .state td {
  padding: 1.25em; }

@media (max-width: 30em) {
  .state thead {
    display: none; }
  .state tr {
    display: block; }
    .state tr:not(:last-child) {
      margin-bottom: 2em; }
  .state td {
    display: block;
    position: relative;
    padding: 1em;
    padding-left: 11.4em; }
    .state td:before {
      content: attr(data-header);
      display: flex;
      align-items: center;
      width: 10.4em;
      height: 100%;
      padding: 1em;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #ffebbd;
      font-weight: 700;
      border-bottom: 2px solid #fff; } }

.state ol {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1em;
  margin: 0; }
  .state ol li {
    padding: 0 0.5em;
    margin-bottom: 1em;
    font-weight: 700; }
    @media (min-width: 58.75em) {
      .state ol li {
        flex-basis: 30%; } }
    @media (min-width: 30.0625em) and (max-width: 40em), (min-width: 48em) and (max-width: 58.6875em) {
      .state ol li {
        flex-basis: 50%; } }
    @media (max-width: 30em), (min-width: 40.0625em) and (max-width: 47.9375em) {
      .state ol li {
        flex-basis: 100%; } }
  .state ol ol {
    display: block;
    margin-top: 1em;
    list-style-type: lower-alpha; }
    .state ol ol li {
      padding: 0;
      font-weight: normal; }

.affiliations {
  padding: 0;
  list-style: none; }
  .affiliations li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1em; }
    .affiliations li img {
      margin-right: 1em; }
    .affiliations li p {
      flex: 1 1 20em; }
    .no-flexbox .affiliations li {
      display: table; }
      .no-flexbox .affiliations li img {
        display: table-cell;
        width: 14.167em;
        vertical-align: middle; }
      .no-flexbox .affiliations li p {
        display: table-cell;
        width: calc(100% - 14.167em);
        padding-left: 0.5em;
        vertical-align: middle; }

.program {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 2em 0; }
  .program img {
    margin-bottom: 1em; }
  .programDescription {
    flex: 1 1 20em;
    padding-right: 2em; }
  .program h3 {
    margin: 0; }
  @media (min-width: 38.9375em) and (max-width: 40em), (min-width: 53.125em) {
    .program img {
      order: 2; }
    .programDescription {
      order: 1; } }
  .no-flexbox .program:after {
    content: '';
    display: table;
    clear: both; }
  .no-flexbox .program img {
    margin-left: 1em;
    float: right; }

.plant {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin: 2em 0; }
  .plants .plant {
    flex-direction: row; }
  .plantThumb {
    flex: 0 0 16.833em;
    margin-right: 2em;
    margin-bottom: 0.5em; }
  .plantDescription {
    flex: 1 1 20em; }
  .plantDetails {
    flex: 1 1 16.667em;
    margin-bottom: 2em; }
  .plantLocation {
    flex: 1 1 26.667em;
    margin-right: 1em; }
  .plantMap {
    width: 100%; }
  .plant h3 {
    margin-top: 0; }
  .plant ul {
    list-style: none;
    padding-left: 0; }
  .plant li {
    margin-bottom: 0.5em; }
  .no-flexbox .plant {
    display: table;
    width: 100%; }
    .no-flexbox .plantThumb, .no-flexbox .plantDescription {
      display: table-cell;
      vertical-align: top; }
    .no-flexbox .plantThumb {
      width: 16.833em;
      margin-right: 0; }
    .no-flexbox .plantDescription {
      padding-left: 1em; }
    .no-flexbox .plantDetails {
      width: 16.667em;
      float: right;
      margin-left: 1em; }
    .no-flexbox .plantLocation {
      width: calc(100% - 17.667em); }

.three-col {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  font-size: 1.167em; }
  .three-col ul {
    padding-left: 0;
    list-style: none; }
  .three-col li {
    flex-basis: 100%;
    margin: 1em;
    text-align: center; }
    .three-col li li {
      margin: 0 0 1em 0; }
    @media (min-width: 30em) {
      .three-col li {
        flex: 0 1 calc(33.333% - 2em); } }
  .three-col img {
    display: block;
    margin: 0 auto 1em; }
  .three-col h4 {
    font-weight: normal;
    color: #930; }
  .three-col .actions img {
    display: inline-block;
    margin: 0 0.5em; }
  .no-flexbox .three-col:after {
    content: '';
    display: table;
    clear: both; }
  @media (min-width: 30em) {
    .no-flexbox .three-col li {
      width: calc(33.333% - 2em);
      float: left; }
      .no-flexbox .three-col li:nth-child(3n+1) {
        clear: left; }
      .no-flexbox .three-col li li {
        width: auto;
        float: none; } }

@media (min-width: 40em) {
  .tinynav {
    display: none; } }

@media (max-width: 64em) {
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .AddToWishList, .DeleteFromWishList {
    display: flex;
    align-items: center;
    width: 12em;
    height: auto;
    min-height: 2.3em; }
  #site {
    width: 100%; }
    #site #header #navigation {
      width: 100%;
      margin-left: 0;
      padding-left: 1em;
      padding-right: 1.7em; }
      #site #header #navigation:after {
        content: '';
        display: table;
        clear: both; }
      #site #header #navigation .nav-item-wishlist {
        float: right;
        margin-right: 1.7em; }
    #site #header .search-section .search {
      width: calc(100% - 235px); }
    #site #header .search-section .logo {
      margin-left: 10px; }
    #site #content {
      display: flex;
      flex-wrap: wrap;
      position: relative; }
      #site #content #HomeContainer #RotatingBanner {
        width: calc(100% - 240px);
        height: 350px;
        float: none;
        margin: 0;
        margin-left: 200px;
        list-style: none;
        position: relative; }
        #site #content #HomeContainer #RotatingBanner:after {
          content: '';
          display: table;
          clear: both; }
        #site #content #HomeContainer #RotatingBanner li {
          width: 100%;
          background: red; }
          #site #content #HomeContainer #RotatingBanner li span {
            width: 247px;
            height: 155px;
            bottom: 0; }
      #site #content #HomeContainer #BottomContainers {
        display: flex; }
        #site #content #HomeContainer #BottomContainers:after {
          content: '';
          display: table;
          clear: both; }
        #site #content #HomeContainer #BottomContainers .FirstImage, #site #content #HomeContainer #BottomContainers .ImageContainer .ImageMessage,
        #site #content #HomeContainer #BottomContainers .LeftBox, #site #content #HomeContainer #BottomContainers .Divider, #site #content #HomeContainer #BottomContainers .ImageContainer {
          float: none; }
        #site #content #HomeContainer #BottomContainers .ImageContainer {
          width: 12vw;
          padding-top: 78%;
          margin: 0 auto;
          -webkit-background-size: contain !important;
          background-size: contain !important; }
        #site #content #HomeContainer #BottomContainers a {
          flex: 1; }
        .no-flexbox #site #content #HomeContainer #BottomContainers a, .no-flexbox #site #content #HomeContainer #BottomContainers .LeftBox, .no-flexbox #site #content #HomeContainer #BottomContainers .Divider {
          float: left; }
        .no-flexbox #site #content #HomeContainer #BottomContainers a {
          margin: 0 1vw; }
        .no-flexbox #site #content #HomeContainer #BottomContainers .ImageContainer {
          width: 16vw; }
      #site #content .LeftContent {
        float: none; }
        .no-flexbox #site #content .LeftContent {
          float: left; }
          .no-flexbox #site #content .LeftContent + .MainContent {
            float: left; }
        #site #content .LeftContent.LeftContentHome {
          position: absolute;
          top: 0;
          left: 0; }
          #site #content .LeftContent.LeftContentHome + #HomeContainer {
            flex-basis: 100%;
            width: 100%; }
        #site #content .LeftContent .searchFilters #BackgroundWrapper {
          width: 100%; }
      #site #content .MainContent {
        flex: 1 1 calc(100% - 220px);
        width: calc(100% - 220px);
        float: none; }
      #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem {
        display: flex; }
        #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryImage {
          flex: 0 0 190px; }
        #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo {
          flex: 1 1 auto;
          min-width: 14em;
          background-size: cover;
          background-position: 0 50%; }
          #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo .SubCategoryInfoBGWrapper {
            background: none;
            padding: 0; }
      #site #content #CategoryContainer .ViewAllButton {
        width: 100%;
        max-width: 508px; }
      #site #content #ProductsSearchContainer {
        height: auto; }
        #site #content #ProductsSearchContainer #ulProductsSearchResults {
          clear: none; }
          #site #content #ProductsSearchContainer #ulProductsSearchResults .DataRepeater {
            display: flex;
            flex-wrap: wrap;
            justify-content: center; }
          #site #content #ProductsSearchContainer #ulProductsSearchResults .Divider {
            display: none; }
          #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem {
            height: auto;
            padding: 1em; } }
          @media (max-width: 64em) and (min-width: 31.9375em) and (max-width: 40em), (max-width: 64em) and (min-width: 45.6875em) {
            #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem {
              margin-right: 2.2em; }
              .no-flexbox #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem {
                margin-right: 2%; } }

@media (max-width: 64em) {
            #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Name, #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Dimensions, #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .SKU {
              margin-bottom: 0.5em; }
            #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Name {
              height: auto; }
            #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .ViewProduct {
              margin: 0.75em auto; }
            #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare {
              display: flex;
              align-items: center; }
              #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare .CompareCheckbox {
                float: none; }
              #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare .CompareText {
                flex: 1;
                width: auto;
                height: auto;
                padding-top: 0; }
              #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare .CompareButton {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                float: none; }
              .no-flexbox #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare {
                display: table; }
                .no-flexbox #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare .CompareCheckbox,
                .no-flexbox #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem .Compare .CompareButton {
                  display: table-cell;
                  vertical-align: middle; }
      #site #content #ProductContainer #ProductInfo {
        display: flex;
        flex-wrap: wrap;
        height: auto; }
        #site #content #ProductContainer #ProductInfo .Header {
          flex: 1 1 100%; }
        #site #content #ProductContainer #ProductInfo #FullImages {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 1 27.5em;
          width: auto;
          height: auto;
          min-height: 27.5em;
          line-height: inherit; }
          #site #content #ProductContainer #ProductInfo #FullImages img {
            width: auto;
            height: auto;
            max-width: 100%; }
        #site #content #ProductContainer #ProductInfo .Info {
          flex-grow: 1;
          flex-shrink: 2; }
          #site #content #ProductContainer #ProductInfo .Info div #DownloadProductImage, #site #content #ProductContainer #ProductInfo .Info div #ViewVideo, #site #content #ProductContainer #ProductInfo .Info div #View360Image {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: auto;
            min-height: 3.5em; }
          #site #content #ProductContainer #ProductInfo .Info #FindADealer {
            margin: 1em auto; }
      #site #content #ProductContainer #ProductTabs {
        display: flex;
        align-items: flex-end;
        border-bottom: 14px solid #0c3961; }
        #site #content #ProductContainer #ProductTabs .Tab {
          display: inline-flex;
          width: auto;
          height: auto;
          padding: 0.5em;
          margin-left: 2.143em;
          position: relative;
          background: #e1c073; }
          #site #content #ProductContainer #ProductTabs .Tab:before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            width: 2.143em;
            height: 100%;
            background: transparent url("/images/Product_TabCorner.png") 100% 0 no-repeat;
            background-size: cover; }
          #site #content #ProductContainer #ProductTabs .Tab.Selected, #site #content #ProductContainer #ProductTabs .Tab:hover {
            background: #0c3961; }
            #site #content #ProductContainer #ProductTabs .Tab.Selected:before, #site #content #ProductContainer #ProductTabs .Tab:hover:before {
              background: transparent url("/images/Product_TabCornerActive.png") 100% 0 no-repeat; }
        .no-flexbox #site #content #ProductContainer #ProductTabs:after {
          content: '';
          display: table;
          clear: both; }
      #site #content #ProductContainer #TabContainers {
        width: 100%;
        padding: 1em;
        box-shadow: 0 0.3em 2em rgba(0, 0, 0, 0.5); }
        #site #content #ProductContainer #TabContainers .TabContainer {
          padding: 0;
          background: none; } }
        @media (max-width: 64em) and (min-width: 26.3125em) {
          #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification {
            display: table;
            width: 100%; }
            #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li {
              display: table-row;
              height: auto;
              padding: 0;
              border: none; }
              #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeTypeName,
              #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeNames {
                display: table-cell;
                width: 100%;
                padding: 0.5em;
                border-bottom: 1px solid #424242; }
              #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeTypeName {
                width: 178px; }
              #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeNames {
                width: calc(100% - 178px); }
              #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li br {
                display: none; } }

@media (max-width: 64em) {
          #site #content #ProductContainer #TabContainers .TabContainer#TabLiteratureDiv a {
            margin-left: 0;
            margin-right: 20px; }
        #site #content #ProductContainer #TabContainers .Background {
          display: none; }
      #site #content #ProductsComparisonContainer {
        width: calc(100% - 2em);
        overflow: auto; }
      #site #content .MainInnerContent {
        width: calc(100% - 227px); }
        #site #content .MainInnerContent iframe {
          width: 100%; }
    #site #footer ul li.footer-content {
      width: calc(100% - 295px);
      padding-right: 1em; }
    #site #footer ul li.footer-copyright #aEsteyTennsco img, #site #footer ul li.footer-copyright #aEsteyTennsco span {
      display: inline;
      vertical-align: middle;
      float: none; }
  #MyWishListItems .MyWishListItem {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 2em 0;
    background: none; }
    #MyWishListItems .MyWishListItem .ProductImage {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 16.667em;
      width: auto;
      height: auto;
      min-height: 16.667em;
      margin-left: 0;
      margin-right: 1em;
      float: none;
      text-align: left;
      line-height: inherit; }
    #MyWishListItems .MyWishListItem .ProductInfo {
      display: flex;
      flex-direction: column;
      flex: 1 1 25em;
      width: auto;
      float: none;
      margin: 1em 0 0;
      padding: 0; }
      #MyWishListItems .MyWishListItem .ProductInfo .ProductDimensions,
      #MyWishListItems .MyWishListItem .ProductInfo .ProductNameWeb,
      #MyWishListItems .MyWishListItem .ProductInfo .ProductSKU {
        height: auto; }
      #MyWishListItems .MyWishListItem .ProductInfo .ProductNameWeb {
        margin-bottom: 0; }
      #MyWishListItems .MyWishListItem .ProductInfo .ProductSKU {
        margin: 0.75em 0; }
      #MyWishListItems .MyWishListItem .ProductInfo .ProductDimensions {
        flex: 1; }
      #MyWishListItems .MyWishListItem .ProductInfo .ProductRemove {
        margin-top: 0.75em; }
  .vDataPager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto; }
    .vDataPager .DataPagerRow {
      order: 2;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: center;
      width: auto;
      height: auto;
      position: static; }
      .vDataPager .DataPagerRow li {
        display: flex;
        justify-content: center;
        align-items: center; }
        .vDataPager .DataPagerRow li a, .vDataPager .DataPagerRow li span {
          float: none;
          background: none; } }
    @media (max-width: 64em) and (max-width: 26.25em) {
      .vDataPager .DataPagerRow {
        flex-basis: 100%; } }

@media (max-width: 64em) {
    .vDataPager .DataPagerDetails {
      order: 1;
      position: static; }
    .no-flexbox .vDataPager:after {
      content: '';
      display: table;
      clear: both; }
    .no-flexbox .vDataPager .DataPagerRow, .no-flexbox .vDataPager .DataPagerRow li, .no-flexbox .vDataPager .DataPagerDetails {
      float: left; }
  .Print {
    margin: 1em 0; }
  .tinynav {
    display: none; } }

@media (min-width: 48.0625em) and (max-width: 64em) {
  #site #content .MainInnerContent td {
    width: 33.333%; }
    #site #content .MainInnerContent td[colspan="3"] {
      width: 100%; }
    #site #content .MainInnerContent td:nth-child(3n+1) {
      clear: both; } }

@media (min-width: 40.0625em) and (max-width: 48em) {
  #site #content .MainInnerContent td {
    width: 50%; }
    #site #content .MainInnerContent td[colspan="3"] {
      width: 100%; }
  #site #content .MainInnerContent tr:nth-child(even) td:nth-child(even),
  #site #content .MainInnerContent tr:nth-child(odd) td:nth-child(odd) {
    clear: both; } }

@media (max-width: 59.9375em) {
  .Form .FirstColumn, .Form .SecondColumn {
    float: none;
    margin: 0; } }

@media (max-width: 53.75em) {
  #site #corner-Top {
    width: 24.9375em;
    height: 1.25em; }
  #site #header #navigation {
    display: none; }
  #site #header .search-section {
    display: flex;
    align-items: center;
    padding-right: 2.5em;
    border-bottom: solid 1px #000000; }
    #site #header .search-section .search {
      flex: 1;
      width: auto;
      height: auto;
      border-bottom: none;
      margin-right: 1.5em; }
      #site #header .search-section .search a {
        margin: 0; }
      #site #header .search-section .search .input {
        margin-top: 0;
        margin-right: 0.3em; }
  #site #content #HomeContainer #BottomContainers .LeftBox {
    flex: 0 0 353px;
    padding-right: 30px; }
  #site #content #ProductContainer #ProductInfo #FullImages {
    flex: 1 1 100%;
    min-height: 0;
    border: none;
    margin-top: 1em; } }

@media (max-width: 53.6875em) {
  #AddressAndContactInfo #TennscoInfo, #AddressAndContactInfo #TennscoMap {
    padding: 0;
    float: none; } }

@media (max-width: 48em) {
  #site #content #HomeContainer #BottomContainers .LeftBox {
    flex: 0 0 260px;
    height: auto;
    padding-right: 30px;
    background: #FFD27F; }
  #site #content #ProductContainer #ProductInfo .Info {
    padding-left: 0; }
  #site #content #ProductContainer #ProductPadding {
    padding: 0; }
  #site #footer {
    height: auto; }
    #site #footer ul {
      display: flex;
      flex-direction: column;
      margin-left: 28px;
      margin-right: 28px; }
      #site #footer ul li.footer-copyright {
        order: 2; }
      #site #footer ul li.footer-content {
        order: 1;
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
        padding-right: 0;
        font-size: 10px; }
  .FlowPlayerContainer {
    width: 80vw;
    height: auto;
    padding: 0; }
    .FlowPlayerContainer .fp-context-menu {
      display: none !important; } }

@media (max-width: 40em) {
  #site #header {
    height: 125px; }
    #site #header .search-section .search {
      border-bottom: none; }
  #site #content {
    display: block; }
    #site #content #HomeContainer #RotatingBanner {
      width: 100%;
      height: 0;
      padding-bottom: 43.75%;
      margin-bottom: 347px;
      margin-left: 0; }
      #site #content #HomeContainer #RotatingBanner li {
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover !important;
        background-position: center !important; }
    #site #content #HomeContainer #BottomContainers {
      display: block;
      padding-top: 0;
      margin-top: -1.2em; }
      #site #content #HomeContainer #BottomContainers .LeftBox {
        width: 100%;
        margin-bottom: 3em; }
      #site #content #HomeContainer #BottomContainers .ImageContainer {
        width: 14em;
        padding-top: 16em;
        height: auto; }
        #site #content #HomeContainer #BottomContainers .ImageContainer .ImageMessage {
          height: auto;
          margin-bottom: 3em; }
      #site #content #HomeContainer #BottomContainers .Divider {
        display: none; }
    #site #content #ProductsSearchContainer {
      margin-top: 1.667em; }
    #site #content .LeftContent {
      width: 100%;
      position: static;
      float: none; }
      #site #content .LeftContent.LeftContentHome {
        margin-top: calc((100vw - 100%)/-2);
        position: absolute;
        top: 43.75vw; }
        #site #content .LeftContent.LeftContentHome .categoriesNav {
          display: block;
          height: 347px;
          margin-bottom: 0;
          background-size: cover; }
      #site #content .LeftContent .categoriesNav {
        background-size: cover;
        display: block;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 1em; }
        #site #content .LeftContent .categoriesNav li.nav-item {
          width: calc(100% - 10px); }
          #site #content .LeftContent .categoriesNav li.nav-item:first-child a {
            margin-top: 0; }
          #site #content .LeftContent .categoriesNav li.nav-item a.nav-item-link:hover, #site #content .LeftContent .categoriesNav li.nav-item a.nav-item-link.selected {
            -webkit-background-size: cover;
            background-size: cover; }
          #site #content .LeftContent .categoriesNav li.nav-item.sub-nav .nav-item-secondary {
            display: block !important;
            height: auto !important;
            position: static; }
            #site #content .LeftContent .categoriesNav li.nav-item.sub-nav .nav-item-secondary li a {
              background: none;
              padding-left: 3em;
              border-top: none;
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              border-left: 1px solid #fff; }
      #site #content .LeftContent .searchFilters #BackgroundWrapper {
        width: 100%; }
      #site #content .LeftContent .searchFilters #AddedBackground {
        height: 2em;
        background-size: cover; }
    #site #content .MainContent {
      width: 100%;
      margin-left: 0; }
    #site #content .MainInnerContent {
      width: 100%; }
    #site #content .LeftInnerContent {
      width: calc(100% - 20px);
      float: none; }
      #site #content .LeftInnerContent .LeftUserContent {
        width: 100%;
        padding: 10px;
        background: #ffefcb; }
        #site #content .LeftInnerContent .LeftUserContent .LeftUserContentMiddle {
          background: none;
          padding: 10px;
          border: 1px solid #b8102d; }
      #site #content .LeftInnerContent .LeftUserContent .LeftUserContentBottom,
      #site #content .LeftInnerContent .StandardNav {
        display: none; }
  #site #corner-Right {
    height: 471px;
    width: 20px; }
  ul[class*="tinynav"] {
    display: none; }
  .tinynav {
    display: block; } }

@media (max-width: 33.75em) {
  #site #header .search-section .logo {
    flex: 1; }
  #site #header .search-section .search {
    display: none; }
  #site #content #ProductsSearchContainer #ulProductsSearchResults .ProductsSearchResultItem {
    flex: 1 1 100%;
    width: 100%;
    margin-right: 0; } }

@media (max-width: 29.9375em) {
  #site #corner-Top {
    width: 100%; }
  #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem {
    flex-wrap: wrap;
    justify-content: center; }
    #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo {
      text-align: center;
      padding-left: 1em;
      padding-right: 1em; }
      #site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo .SubCategoryInfoBGWrapper .SubCategoryInfoButton {
        margin: 0 auto; }
  h1 {
    text-align: center;
    margin-top: 0.5em; } }

@media (max-width: 26.25em) {
  #site #header .search-section {
    border-bottom: none; }
  #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li {
    height: auto;
    padding: 0.5em; }
    #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeTypeName,
    #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeNames {
      display: block;
      width: 100%;
      float: none; }
    #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li .AttributeNames {
      margin-top: 0.3em;
      padding-left: 1em; }
    #site #content #ProductContainer #TabContainers .TabContainer#TabSpecificationsDiv #ProductSpecification li br {
      display: none; }
  #MyWishListItems .MyWishListItem .ProductImage {
    flex: 1 1 100%;
    margin-right: 0; }
  .Form .SecondColumn .CheckboxList li,
  .Form .FirstColumn .CheckboxList li {
    width: auto;
    float: none; }
  .Form .SecondColumn .FieldInput label,
  .Form .FirstColumn .FieldInput label {
    float: none; }
  .Form .vCheckbox,
  .Form .vRadio {
    float: none;
    display: inline-block;
    vertical-align: middle; }
  [type="text"] {
    width: 100%; } }
