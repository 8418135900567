@import "../../../node_modules/breakpoint-sass/stylesheets/breakpoint.scss";
@include breakpoint-set('to ems', true);

$tabSection: tabSection;

html, body {
	&.menu-open {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
	}
}

img {
	max-width: 100%;
	height: auto;

}

.alignright {
	margin: 0 0 1em 1em;
	float: right;
}

.alignleft {
	margin: 0 1em 1em 0;
	float: left;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@include breakpoint(max-width 767px) {
	.aligncenter,
	.alignright,
	.alignleft {
		display: block;
		margin-left: 0;
		margin-right: 0;
		float: none;
	}
}

#MyWishListItems .MyWishListItem .ProductInfo .ProductNameWeb {
	height: auto;
	margin-bottom: 0.75em;
}

#site #content .LeftInnerContent .StandardNav #LeftNavProductCatalog {
	margin: 1em auto 4em;
}

#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo,
#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo .SubCategoryInfoBGWrapper {
	background: none !important;
}

#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryImage,
#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo,
#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem {
	height: auto;
	min-height: auto;
	line-height: inherit;
}

#site #content #CategoryContainer #SubCategoryList .SubCategoryListItem .SubCategoryInfo {
	padding-bottom: 20px;
}

#site #content #HomeContainer #RotatingBanner li span {
	display: none !important;
}

.vDialog .vDialogClose {
	z-index: 1;
}

.vDialog .vDialogContent {
	z-index: 0;
}

.sidebarList {
	padding: 0;
	list-style: none;

	li {
		&:not(:last-child) {
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 2px;
				margin: 10px 0;
				background: transparent url("/images/leftContent-Divider.png") no-repeat center;
			}
		}
	}
}

.backToTop {
	a {
		color: #c00f33;

		&:before {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid #c00f33;
		}
	}
}

.mobileTabs {
	margin: 0 -20px;
	.#{$tabSection} {
		&Title {
			padding: 0.875em 1.429em;
			background: #ffd87c;
			font-size: 1.167em;
			font-weight: bold;
			text-transform: uppercase;
			box-shadow: inset 0.5em -0.2em 1.7em rgba(0, 0, 0, 0.25);
			cursor: pointer;
		}

		&Content {
			display: none;
			padding: 1em 1.6em;
		}

		&.is-active {
			.#{$tabSection} {
				&Title {
					background: #003768;
					color: #fff;
				}

				&Content {
					display: block;
				}
			}
		}
	}

	#ProductSpecification {
		padding: 0;
		margin: 0 -1.6em;
		list-style: none;

		li {
			display: flex;
			align-items: center;
			padding: 0.5em 1.6em;
			border-bottom: 1px solid #424242;
		}

		.AttributeTypeName {
			flex: 0 0 12em;
			font-weight: bold;
		}
	}

	.Header {
		display: block;
		border-bottom: 1px solid #000;
		padding: 0.3em 1.6em;
		margin: 0 -1.6em 0.75em;
	}

	.Color {
		display: block;
		width: 5.333em;
		height: 5.333em;
		margin: 0 auto 0.5em;
		border-radius: 50%;

		&sList {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			margin: 0;
			list-style: none;
		}

		&Item {
			flex: 0 0 9.167em;
			padding: 0 0.5em;
			margin-bottom: 1em;
			text-align: center;
		}
	}
}

.navigation {
	&Button {
		display: block;
		width: 2em;
		height: 2em;
		padding: 0;
		border: none;
		position: relative;
		background: none;
		font-size: 1em;
		appearance: none;
		cursor: pointer;

		&:before {
			content: '';
			width: 100%;
			height: 0.5em;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background-color: #1b3a67;
			box-shadow: 0 -0.75em 0 #1b3a67, 0 0.75em 0 #1b3a67;
		}
	}

	&--mobile {
		width: 90%;
		max-width: 40em;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
		transform: translateX(100%);
		overflow: auto;
		background: #61a6d1;
		box-shadow: 0 0 1em rgba(#000, 0.5);
		transition: transform 0.3s;

		.touch & {
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;

		}

		&.is-open {
			transform: translateX(0);
		}

		.search {
			display: none;
			align-items: center;
			padding: 1em;
			list-style: none;

			a {
				display: block;
				order: 2;
				width: 3.167em;
				height: 3.167em;
				margin-left: 1em;
				background: url(../images/search-icon.svg) no-repeat;
				border: 1px solid #fff;
				border-radius: 50%;
			}

			.input {
				flex: 1;
				order: 1;

				input {
					width: 100%;
					height: 3.167em;
					padding: 0.5em 0.75em;
					border: 1px solid #c5c2c1;
					font-size: 1em;
					color: #6f6764;
				}
			}

			@include breakpoint(max-width 540px) {
				display: flex;
			}
		}
	}

	&Menu {
		flex-basis: 100%;
		min-height: calc(100% - 2em);
		padding: 0;
		border: 1px solid #fff;
		margin: 1em;
		list-style: none;
	}

	&SubMenu {
		display: none;
		margin: 0;
		padding: 0 0 0 2em;
		border: none;
		border-top: 1px solid #fff;

		&Button {
			flex: 0 0 4em;
			padding: 0;
			border: none;
			border-left: 1px solid #fff;
			position: relative;
			background: none;
			font-size: 1em;
			appearance: none;
			cursor: pointer;

			&:before {
				content: '';
				width: 1em;
				height: 1em;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(-45deg);
				transition: transform 0.2s;
			}

			&:hover {
				background-color: #327cad;
				box-shadow: inset 0.4em 0.4em 0.7em #274962;
			}

			&.is-open {
				&:before {
					transform: translate(-50%, -50%) rotate(45deg);
				}
			}
		}
	}

	&Item {
		display: flex;
		flex-wrap: wrap;

		a {
			display: block;
			flex: 1;
			margin: 0;
			padding: 1em;
			color: #fff;
			font-size: 1.167em;
			font-weight: bold;
			text-transform: uppercase;

			&:hover {
				background-color: #327cad;
				box-shadow: inset 0.4em 0.4em 0.7em #274962;
			}
		}

		&:not(:last-child) {
			border-bottom: 1px solid #fff;
		}

		&:not(.navigationSubItem):last-child {
			border-bottom: 1px solid #fff;
		}
	}

	&SubItem {
		border-left: 1px solid #fff;
	}
}

.mobileSlider {
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;

	&Container {
		max-width: 45em;
		margin: 2em auto;
	}

	.slick {

		&-track {
			width: 100%;
		}

		&-list {
			flex: 1;
		}

		&-arrow {
			flex: 0 0 2.333em;
			background: transparent center no-repeat;
			border: none;
			padding: 0;
			overflow: hidden;
			font-size: 1em;
			text-indent: 100%;
			white-space: nowrap;
			appearance: none;
			cursor: pointer;
		}

		&-prev {
			margin-right: 0.5em;
			background-image: url("/images/Product_Thumb_LeftArrow.png");
		}

		&-next {
			margin-left: 0.5em;
			background-image: url("/images/Product_Thumb_RightArrow.png");
		}

		&-slide {
			border: 5px solid transparent;
			margin: 0 0.5em;

			&.is-selected,
			&:hover {
				border-color: #ffd87c;
			}

			img {
				width: 100%;
				height: auto;
				border: 1px solid #000;
			}

			@include breakpoint(max-width 420px) {
				margin: 0 5em;
			}
		}
	}
}

.available-literature {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 28em;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	font-size: 1.167em;

	li {
		flex: 1 1 calc(50% - 2em);
		margin: 1em;
		text-align: center;
	}

	img {
		display: block;
		margin: 0 auto 1em;
	}

	.no-flexbox & {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		li {
			width: calc(50% - 2em);
			float: left;

			&:nth-child(2n+1) {
				clear: left;
			}
		}
	}
}

.application-shots {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	font-size: 1.167em;

	li {
		flex-basis: 100%;
		margin: 1em;
		text-align: center;

		@include breakpoint(480px) {
			flex: 0 1 calc(50% - 2em);
		}
	}

	img {
		display: block;
		margin: 0 auto 1em;
	}

	.no-flexbox & {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		@include breakpoint(480px) {
			li {
				width: calc(50% - 2em);
				float: left;

				&:nth-child(2n+1) {
					clear: left;
				}
			}
		}
	}
}

.state {
	// font-size: 1.167em;

	table {
		width: 100%;
		margin: 2em 0;
		border-collapse: collapse;
	}

	thead {
		th {
			position: relative;
			background-color: #ffebbd;
			text-align: left;

			&:not(:last-child) {
				&:after {
					content: '';
					width: 2px;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
					background-color: #fff;
				}
			}
		}
	}

	tbody {
		td {
			background-color: #eff6fa;
		}
	}

	th, td {
		padding: 1.25em;
	}

	@include breakpoint(max-width 480px) {
		thead {
			display: none;
		}

		tr {
			display: block;

			&:not(:last-child) {
				margin-bottom: 2em;
			}
		}

		td {
			display: block;
			position: relative;
			padding: 1em;
			padding-left: 11.4em;

			&:before {
				content: attr(data-header);
				display: flex;
				align-items: center;
				width: 10.4em;
				height: 100%;
				padding: 1em;
				position: absolute;
				left: 0;
				top: 0;
				background-color: #ffebbd;
				font-weight: 700;
				border-bottom: 2px solid #fff;
			}
		}
	}

	ol {
		display: flex;
		flex-wrap: wrap;
		padding: 0 1em;
		margin: 0;

		li {
			padding: 0 0.5em;
			margin-bottom: 1em;
			font-weight: 700;

			@include breakpoint(940px) {
				flex-basis: 30%;
			}

			@include breakpoint(((481px 640px), (768px 939px))) {
				flex-basis: 50%;
			}

			@include breakpoint(((max-width 480px), (641px 767px))) {
				flex-basis: 100%;
			}
		}

		ol {
			display: block;
			margin-top: 1em;
			list-style-type: lower-alpha;

			li {
				padding: 0;
				font-weight: normal;
			}
		}
	}
}

.affiliations {
	padding: 0;
	list-style: none;

	li {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 1em;

		img {
			margin-right: 1em;
		}

		p {
			flex: 1 1 20em;
		}

		.no-flexbox & {
			display: table;

			img {
				display: table-cell;
				width: 14.167em;
				vertical-align: middle;
			}

			p {
				display: table-cell;
				width: calc(100% - 14.167em);
				padding-left: 0.5em;
				vertical-align: middle;
			}
		}
	}
}

.program {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin: 2em 0;

	img {
		margin-bottom: 1em;
	}

	&Description {
		flex: 1 1 20em;
		padding-right: 2em;
	}

	h3 {
		margin: 0;
	}

	@include breakpoint(((623px 640px), (850px))) {
		img {
			order: 2;
		}

		&Description {
			order: 1;
		}
	}

	.no-flexbox & {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		img {
			margin-left: 1em;
			float: right;
		}
	}
}

.plant {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row-reverse;
	margin: 2em 0;

	.plants & {
		flex-direction: row;
	}

	&Thumb {
		flex: 0 0 16.833em;
		margin-right: 2em;
		margin-bottom: 0.5em;
	}

	&Description {
		flex: 1 1 20em;
	}

	&Details {
		flex: 1 1 16.667em;
		margin-bottom: 2em;
	}

	&Location {
		flex: 1 1 26.667em;
		margin-right: 1em;
	}

	&Map {
		width: 100%;
	}

	h3 {
		margin-top: 0;
	}

	ul {
		list-style: none;
		padding-left: 0;
	}

	li {
		margin-bottom: 0.5em;
	}

	.no-flexbox & {
		display: table;
		width: 100%;

		&Thumb, &Description {
			display: table-cell;
			vertical-align: top;
		}

		&Thumb {
			width: 16.833em;
			margin-right: 0;
		}

		&Description {
			padding-left: 1em;
		}

		&Details {
			width: 16.667em;
			float: right;
			margin-left: 1em;
		}

		&Location {
			width: calc(100% - 17.667em);
		}
	}
}

.three-col {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	font-size: 1.167em;

	ul {
		padding-left: 0;
		list-style: none;
	}

	li {
		flex-basis: 100%;
		margin: 1em;
		text-align: center;

		li {
			margin: 0 0 1em 0;
		}

		@include breakpoint(480px) {
			flex: 0 1 calc(33.333% - 2em);
		}
	}

	img {
		display: block;
		margin: 0 auto 1em;
	}

	h4 {
		font-weight: normal;
		color: #930;
	}

	.actions {
		img {
			display: inline-block;
			margin: 0 0.5em;
		}
	}

	.no-flexbox & {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		@include breakpoint(480px) {
			li {
				width: calc(33.333% - 2em);
				float: left;

				&:nth-child(3n+1) {
					clear: left;
				}

				li {
					width: auto;
					float: none;
				}
			}
		}
	}
}

@include breakpoint(640px) {
	.tinynav {
		display: none;
	}
}

@include breakpoint(max-width 1024px) {
	*, *:before, *:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.AddToWishList, .DeleteFromWishList {
		display: flex;
		align-items: center;
		width: 12em;
		height: auto;
		min-height: 2.3em;
	}

	#site {
		width: 100%;

		#header {
			#navigation {
				width: 100%;
				margin-left: 0;
				padding-left: 1em;
				padding-right: 1.7em;

				&:after {
					content: '';
					display: table;
					clear: both;
				}

				.nav-item-wishlist {
					float: right;
					margin-right: 1.7em;
				}
			}

			.search-section {
				.search {
					width: calc(100% - 235px);
				}

				.logo {
					margin-left: 10px;
				}
			}
		}

		#content {
			display: flex;
			flex-wrap: wrap;
			position: relative;

			#HomeContainer {
				#RotatingBanner {
					width: calc(100% - 240px);
					height: 350px;
					float: none;
					margin: 0;
					margin-left: 200px;
					list-style: none;
					position: relative;

					&:after {
						content: '';
						display: table;
						clear: both;
					}

					li {
						width: 100%;
						background: red;

						span {
							width: 247px;
							height: 155px;
							bottom: 0;
						}
					}
				}

				#BottomContainers {
					display: flex;

					&:after {
						content: '';
						display: table;
						clear: both;
					}

					.FirstImage, .ImageContainer .ImageMessage,
					.LeftBox, .Divider, .ImageContainer {
						float: none
					}

					.ImageContainer {
						width: 12vw;
						padding-top: 78%;
						margin: 0 auto;
						-webkit-background-size: contain !important;
						background-size: contain !important;
					}

					a {
						flex: 1;
					}

					.no-flexbox & {
						a, .LeftBox, .Divider {
							float: left;
						}

						a {
							margin: 0 1vw;
						}

						.ImageContainer {
							width: 16vw;
						}
					}
				}
			}

			.LeftContent {
				float: none;

				.no-flexbox & {
					float: left;

					+ .MainContent {
						float: left;
					}
				}

				&.LeftContentHome {
					position: absolute;
					top: 0;
					left: 0;

					+ #HomeContainer {
						flex-basis: 100%;
						width: 100%;
					}
				}

				.searchFilters {
					#BackgroundWrapper {
						width: 100%;
					}
				}
			}

			.MainContent {
				flex: 1 1 calc(100% - 220px);
				width: calc(100% - 220px);
				// margin-left: 200px;
				float: none;
			}

			#CategoryContainer {

				#SubCategoryList {

					.SubCategoryListItem {
						display: flex;

						.SubCategoryImage {
							flex: 0 0 190px;
						}

						.SubCategoryInfo {
							flex: 1 1 auto;
							min-width: 14em;
							background-size: cover;
							background-position: 0 50%;

							.SubCategoryInfoBGWrapper {
								background: none;
								padding: 0;
							}
						}
					}
				}

				.ViewAllButton {
					width: 100%;
					max-width: 508px;
				}
			}
			#ProductsSearchContainer {
				height: auto;

				#ulProductsSearchResults {
					clear: none;

					.DataRepeater {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
					}

					.Divider {
						display: none;
					}

					.ProductsSearchResultItem {
						height: auto;
						padding: 1em;

						@include breakpoint(((511px 640px), (731px))) {
							margin-right: 2.2em;

							.no-flexbox & {
								margin-right: 2%;
							}
						}

						.Name, .Dimensions, .SKU {
							margin-bottom: 0.5em;
						}

						.Name {
							height: auto;
						}

						.ViewProduct {
							margin: 0.75em auto;
						}

						.Compare {
							display: flex;
							align-items: center;

							.CompareCheckbox {
								float: none;
							}

							.CompareText {
								flex: 1;
								width: auto;
								height: auto;
								padding-top: 0;
							}

							.CompareButton {
								display: inline-flex;
								align-items: center;
								justify-content: center;
								padding: 0;
								float: none;
							}

							.no-flexbox & {
								display: table;

								.CompareCheckbox,
								.CompareButton {
									display: table-cell;
									vertical-align: middle;
								}
							}
						}
					}
				}
			}

			#ProductContainer {
				#ProductInfo {
					display: flex;
					flex-wrap: wrap;
					height: auto;

					.Header {
						flex: 1 1 100%;
					}

					#FullImages {
						display: flex;
						align-items: center;
						justify-content: center;
						flex: 0 1 27.5em;
						width: auto;
						height: auto;
						min-height: 27.5em;
						line-height: inherit;

						img {
							width: auto;
							height: auto;
							max-width: 100%;
						}
					}

					.Info {
						flex-grow: 1;
						flex-shrink: 2;

						div {
							#DownloadProductImage, #ViewVideo, #View360Image {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								width: auto;
								min-height: 3.5em;
							}
						}

						#FindADealer {
							margin: 1em auto;
						}
					}
				}

				#ProductTabs {
					display: flex;
					align-items: flex-end;
					border-bottom: 14px solid #0c3961;

					.Tab {
						display: inline-flex;
						width: auto;
						height: auto;
						padding: 0.5em;
						margin-left: 2.143em;
						position: relative;
						background: #e1c073;

						&:before {
							content: '';
							position: absolute;
							top: 0;
							right: 100%;
							width: 2.143em;
							height: 100%;
							background: transparent url("/images/Product_TabCorner.png") 100% 0 no-repeat;
							background-size: cover;
						}

						&.Selected, &:hover {
							background: #0c3961;

							&:before {
								background: transparent url("/images/Product_TabCornerActive.png") 100% 0 no-repeat;
							}
						}
					}

					.no-flexbox & {
						&:after {
							content: '';
							display: table;
							clear: both;
						}
					}
				}

				#TabContainers {
					width: 100%;
					padding: 1em;
					box-shadow: 0 0.3em 2em rgba(0, 0, 0, 0.5);

					.TabContainer {
						padding: 0;
						background: none;

						&#TabSpecificationsDiv {
							@include breakpoint(421px) {
								#ProductSpecification {
									display: table;
									width: 100%;

									li {
										display: table-row;
										height: auto;
										padding: 0;
										border: none;

										.AttributeTypeName,
										.AttributeNames {
											display: table-cell;
											width: 100%;
											padding: 0.5em;
											border-bottom: 1px solid #424242;
										}

										.AttributeTypeName {
											width: 178px;
										}

										.AttributeNames {
											width: calc(100% - 178px);
										}

										br {
											display: none;
										}
									}
								}
							}
						}

						&#TabLiteratureDiv {
							a {
								margin-left: 0;
								margin-right: 20px;
							}
						}
					}

					.Background {
						display: none;
					}

				}
			}

			#ProductsComparisonContainer {
				width: calc(100% - 2em);
				overflow: auto;
			}

			.MainInnerContent {
				width: calc(100% - 227px);

				iframe {
					width: 100%;
				}

				// table, tbody {
				// 	display: block;
				// 	width: 100% !important;
				// }

				// table {
				// 	&:after {
				// 		content: '';
				// 		display: table;
				// 		clear: both;
				// 	}
				// }

				// tr {
				// 	display: inline;
				// }

				// td {
				// 	float: left;
				// 	width: 100%;

				// 	&[colspan="3"] {
				// 		overflow: hidden;
				// 	}
				// }
			}
		}

		#footer {
			ul {
				li {
					&.footer-content {
						width: calc(100% - 295px);
						padding-right: 1em;
					}

					&.footer-copyright {
						#aEsteyTennsco {
							img, span {
								display: inline;
								vertical-align: middle;
								float: none;
							}
						}
					}
				}
			}
		}
	}

	#MyWishListItems {
		.MyWishListItem {
			display: flex;
			flex-wrap: wrap;
			height: auto;
			padding: 2em 0;
			background: none;

			.ProductImage {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 16.667em;
				width: auto;
				height: auto;
				min-height: 16.667em;
				margin-left: 0;
				margin-right: 1em;
				float: none;
				text-align: left;
				line-height: inherit;
			}

			.ProductInfo {
				display: flex;
				flex-direction: column;
				flex: 1 1 25em;
				width: auto;
				float: none;
				margin: 1em 0 0;
				padding: 0;

				.ProductDimensions,
				.ProductNameWeb,
				.ProductSKU {
					height: auto;
				}

				.ProductNameWeb {
					margin-bottom: 0;
				}

				.ProductSKU {
					margin: 0.75em 0;
				}

				.ProductDimensions {
					flex: 1;
				}

				.ProductRemove {
					margin-top: 0.75em;
				}
			}
		}
	}

	.vDataPager {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		height: auto;

		.DataPagerRow {
			order: 2;
			display: flex;
			flex: 1;
			flex-wrap: wrap;
			justify-content: center;
			width: auto;
			height: auto;
			position: static;

			li {
				display: flex;
				justify-content: center;
				align-items: center;

				a, span {
					float: none;
					background: none;
				}
			}

			@include breakpoint( max-width 420px ) {
				flex-basis: 100%;
			}
		}

		.DataPagerDetails {
			order: 1;
			position: static;
		}

		.no-flexbox & {
			&:after {
				content: '';
				display: table;
				clear: both;
			}

			.DataPagerRow, .DataPagerRow li, .DataPagerDetails {
				float: left;
			}
		}
	}

	.Print {
		margin: 1em 0;
	}

	.tinynav {
		display: none;
	}
}
@include breakpoint(769px 1024px) {
	#site {
		#content {
			.MainInnerContent {
				td {
					width: 33.333%;

					&[colspan="3"] {
						width: 100%;
					}

					&:nth-child(3n+1) {
						clear: both;
					}
				}
			}
		}
	}
}
@include breakpoint(641px 768px) {
	#site {
		#content {
			.MainInnerContent {
				td {
					width: 50%;

					&[colspan="3"] {
						width: 100%;
					}
				}

				tr {
					&:nth-child(even) td:nth-child(even),
					&:nth-child(odd) td:nth-child(odd) {
						clear: both;
					}
				}
			}
		}
	}
}
@include breakpoint(max-width 959px) {
	.Form {
		.FirstColumn, .SecondColumn {
			float: none;
			margin: 0;
		}
	}
}
@include breakpoint(max-width 860px) {
	#site {
		#corner-Top {
			width: 24.9375em;
			height: 1.25em;
		}

		#header {
			#navigation {
				display: none;
			}

			.search-section {
				display: flex;
				align-items: center;
				padding-right: 2.5em;
				border-bottom: solid 1px #000000;

				.search {
					flex: 1;
					width: auto;
					height: auto;
					border-bottom: none;
					margin-right: 1.5em;

					a {
						margin: 0;
					}

					.input {
						margin-top: 0;
						margin-right: 0.3em;
					}
				}
			}
		}

		#content {
			#HomeContainer {
				#BottomContainers {
					.LeftBox {
						flex: 0 0 353px;
						padding-right: 30px;
					}
				}
			}

			#ProductContainer {
				#ProductInfo {
					#FullImages {
						flex: 1 1 100%;
						min-height: 0;
						border: none;
						margin-top: 1em;
					}
				}
			}
		}
	}

}
@include breakpoint(max-width 859px) {
	#AddressAndContactInfo {
		#TennscoInfo, #TennscoMap {
			padding: 0;
			float: none;
		}
	}
}

@include breakpoint(max-width 768px) {
	#site {
		#content {
			#HomeContainer {
				#BottomContainers {
					.LeftBox {
						flex: 0 0 260px;
						height: auto;
						padding-right: 30px;
						background: #FFD27F;
					}
				}
			}

			#ProductContainer {
				#ProductInfo {
					.Info {
						padding-left: 0;
					}
				}
				#ProductPadding {
					padding: 0;
				}
			}
		}

		#footer {
			height: auto;

			ul {
				display: flex;
				flex-direction: column;
				margin-left: 28px;
				margin-right: 28px;

				li {
					&.footer-copyright {
						order: 2;
					}

					&.footer-content {
						order: 1;
						width: 100%;
						margin-left: 0;
						margin-bottom: 20px;
						padding-right: 0;
						font-size: 10px;
					}
				}
			}
		}
	}

	.FlowPlayerContainer {
		width: 80vw;
		height: auto;
		padding: 0;

		.fp-context-menu {
			display: none !important;
		}
	}
}

@include breakpoint(max-width 640px) {
	#site {
		#header {
			height: 125px;

			.search-section {
				.search {
					border-bottom: none;
				}
			}
		}

		#content {
			display: block;
			#HomeContainer {
				#RotatingBanner {
					width: 100%;
					height: 0;
					padding-bottom: 43.75%;
					margin-bottom: 347px;
					margin-left: 0;

					li {
						height: 100%;
						top: 0;
						left: 0;
						background-size: cover !important;
						background-position: center !important;
					}
				}

				#BottomContainers {
					display: block;
					padding-top: 0;
					margin-top: -1.2em;;

					.LeftBox {
						width: 100%;
						margin-bottom: 3em;
					}

					.ImageContainer {
						width: 14em;
						padding-top: 16em;
						height: auto;

						.ImageMessage {
							height: auto;
							margin-bottom: 3em;
						}
					}

					.Divider {
						display: none;
					}
				}

			}

			#ProductsSearchContainer {
				margin-top: 1.667em;
			}

			.LeftContent {
				width: 100%;
				position: static;
				float: none;

				&.LeftContentHome {
					margin-top: calc((100vw - 100%)/-2);
					position: absolute;
					top: 43.75vw;

					.categoriesNav {
						display: block;
						height: 347px;
						margin-bottom: 0;
						background-size: cover;
					}
				}

				.categoriesNav {
					background-size: cover;
					display: block;
					height: auto;
					padding-top: 5px;
					padding-bottom: 5px;
					margin-bottom: 1em;

					li {
						&.nav-item {
							width: calc(100% - 10px);

							&:first-child {
								a {
									margin-top: 0;
								}
							}

							a {
								&.nav-item-link:hover,
								&.nav-item-link.selected {
									-webkit-background-size: cover;
									background-size: cover;
								}
							}

							&.sub-nav {
								.nav-item-secondary {
									display: block !important;
									height: auto !important;
									position: static;

									li {
										a {
											background: none;
											padding-left: 3em;
											border-top: none;
											border-bottom: 1px solid #fff;
											border-right: 1px solid #fff;
											border-left: 1px solid #fff;
										}
									}
								}
							}
						}
					}
				}

				.searchFilters {
					#BackgroundWrapper {
						width: 100%;
					}

					#AddedBackground {
						height: 2em;
						background-size: cover;
					}
				}
			}

			.MainContent {
				width: 100%;
				margin-left: 0;
			}

			.MainInnerContent {
				width: 100%;
			}

			.LeftInnerContent {
				width: calc(100% - 20px);
				float: none;

				.LeftUserContent {
					width: 100%;
					padding: 10px;
					background: #ffefcb;

					.LeftUserContentMiddle {
						background: none;
						padding: 10px;
						border: 1px solid #b8102d;
					}

				}

				.LeftUserContent .LeftUserContentBottom,
				.StandardNav {
					display: none;
				}
			}

		}

		#corner-Right {
			height: 471px;
			width: 20px;
		}
	}

	ul[class*="tinynav"] {
		display: none;
	}

	.tinynav {
		display: block;
	}
}

@include breakpoint(max-width 540px) {
	#site {
		#header {
			.search-section {
				.logo {
					flex: 1;
				}
				.search {
					display: none;
				}
			}
		}

		#content {
			#ProductsSearchContainer {
				#ulProductsSearchResults {
					.ProductsSearchResultItem {
						flex: 1 1 100%;
						width: 100%;
						margin-right: 0;
					}
				}
			}
		}
	}

}

@include breakpoint(max-width 479px) {
	#site {
		#corner-Top {
			width: 100%;
		}

		#content {
			#CategoryContainer {
				#SubCategoryList {
					.SubCategoryListItem {
						flex-wrap: wrap;
						justify-content: center;

						.SubCategoryInfo {
							text-align: center;
							padding-left: 1em;
							padding-right: 1em;

							.SubCategoryInfoBGWrapper {
								.SubCategoryInfoButton {
									margin: 0 auto;
								}
							}
						}
					}
				}
			}
		}
	}


	h1 {
		text-align: center;
		margin-top: 0.5em;
	}
}

@include breakpoint(max-width 420px) {
	#site {
		#header {
			.search-section {
				border-bottom: none;
			}
		}

		#content {
			#ProductContainer {
				#TabContainers {
					.TabContainer {
						&#TabSpecificationsDiv {
							#ProductSpecification {
								li {
									height: auto;
									padding: 0.5em;

									.AttributeTypeName,
									.AttributeNames {
										display: block;
										width: 100%;
										float: none;
									}

									.AttributeNames {
										margin-top: 0.3em;
										padding-left: 1em;
									}

									br {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	#MyWishListItems {
		.MyWishListItem {
			.ProductImage {
				flex: 1 1 100%;
				margin-right: 0;
			}
		}
	}

	.Form {
		.SecondColumn,
		.FirstColumn {
			.CheckboxList {
				li {
					width: auto;
					float: none;
				}
			}

			.FieldInput {
				label {
					float: none;
				}
			}
		}

		.vCheckbox,
		.vRadio {
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}

	[type="text"] {
		width: 100%;
	}
}
